<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    banners() {
      return this.block.fields.Texts;
    },
    remainder() {
      return this.block.fields.Texts.length % 4 || 0;
    },

  },
  methods: {
    setWidth(idx) {
      if (idx >= this.banners.length - this.remainder) {
        return 'text-banners__banner--last-row';
      }
      return '';
    },
    bannerWidth(idx) {
      if (idx >= this.banners.length - this.remainder) {
        return this.remainder > 0 ? `${100 / this.remainder}%` : '100%';
      }
      return '';
    },
  },
};
</script>

<template>
  <div class="text-banners__container">
    <div
      v-for="(banner, idx) in banners"
      :key="idx"
      v-html="banner.Text"
      :class="['text-banners__banner', setWidth(idx)]"
      :style="{width: bannerWidth(idx)}"
    >
    </div>
  </div>
</template>

<style>
.text-banners__container {
  display: flex;
  flex-direction: column;
  max-width: var(--layout-maxWidth);
  padding: 0 10px;
  margin: auto;
}

@media (max-width: 767px) {
  .text-banners__banner--last-row {
    width: auto !important;
  }
}

@media (min-width: 768px) {
  .text-banners__container {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .text-banners__banner {
    width: 25%;
  }
}
</style>
